import axios from "axios";

import { setError } from "../Utils/showSnackbar";
import { logout } from "../Store/Auth/actions";
import store from "../Store/store";

const agent = axios.create({
  // Development
  // baseURL: "https://api-fdc-dev.ibbr.info/api",
  // Uat
  // baseURL: "https://api-uat-mobile-ibbr.assist.id/api",
  // Production FDC
  // baseURL: process.env.BACKEND_URL,
  // Production FDC Fast
  baseURL: "https://api-fdc.ibbr.info/api",
  // Production DUI
  // baseURL: "https://api-mobile-dui.assist.id/api",
  // Local URL
  // baseURL: "http://localhost:4000/api",
  //
});

agent.interceptors.request.use(function (config) {
  config.headers.Authorization = store.getState().auth.token;

  return config;
});

agent.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response) {
      if (store.getState().auth.isLoggedIn && error.response.status === 401) {
        store.dispatch(logout());
        setError("Error! Silakan login kembali");
      } else if (error.response.data.error?.message === "login failed") {
        setError("Warning! Login gagal, cek email dan password Anda");
      } else if (
        error.response.data.error?.message === "Account is not exists"
      ) {
        setError("Error! Akun tidak ditemukan");
      } else {
        setError(error.response.data.error?.message);
      }
    }
    return Promise.reject(error);
  }
);

export default agent;
